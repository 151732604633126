import dayjs from "dayjs"
import { isEmbroideryProductsInCart } from "@tential/ec-gql-schema/utils/embroidery"
import { useStore } from "~/stores"

export const useStoreOrder = () => {
  const route = useRoute()
  const store = useStore()
  const storeId = (route.query.store_id as string) ?? ""
  const isEmbroidery = computed(() => isEmbroideryProductsInCart(store.cartList))
  /**
   * 店舗決済状態かどうか
   * 店舗決済の場合、eギフトと刺繍サービスは設定しない
   *  eギフト: OMSの登録とラグが生まれるため（eギフトの要望あれば経理と相談）
   *  刺繍サービス: 今後の拡張性を考慮し、不要の判断
   * 支払い方法はクレジットカード、AmazonPayのみ
   */
  const visitStore = computed(() => store.visitStore)

  /**
   * 訪れた店舗の情報と有効期限をセットする
   */
  const setVisitStore = () => {
    // 店舗IDがあるかつ、eGiftが選択されていないかつ、刺繍商品がカートにない場合に訪問店舗情報をセット
    if (storeId && !store.eGift.is_select && !isEmbroidery.value) {
      const effectiveUnixtime = dayjs().add(1, "hour").valueOf()
      store.setVisitStore({
        storeId,
        effectiveUnixtime,
        isExpired: false,
      })
    } else {
      checkAndUpdateExpiration()
    }
  }
  /**
   * 店舗決済の有効期限を確認して更新する
   */
  const checkAndUpdateExpiration = () => {
    const now = dayjs().valueOf()
    const visitStore = store.visitStore
    if (!visitStore.isExpired && visitStore.effectiveUnixtime < now) {
      store.resetVisitStore()
    }
  }

  return { setVisitStore, checkAndUpdateExpiration, visitStore }
}
